.react-draggable,
.cursor {
  cursor: move;
}
.no-cursor {
  cursor: auto;
}
.cursor-y {
  cursor: ns-resize;
}
.cursor-x {
  cursor: ew-resize;
}

.react-draggable strong {
  background: #ddd;
  // background: rgb(255, 0, 0);
  border: 1px solid #999;
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  padding: 3px 5px;
  text-align: center;
  font-size: 20px;
}
.title{
  background: white;
}
.box {
  background: #fff;
  // background: rgb(0, 255, 200);
  border: 1px solid #999;
  // border-radius: 3px;
  position: fixed;
  overflow: hidden;
  z-index: 1200;
  // width: 100vh;
  //   height: 180px;
  // margin: 12px;
  padding: 10px;
  float: left;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}
.rem-position-fix {
  position: static !important;
}

#chatborder {
  border-style: solid;
  border: 1px solid #999;
  background-color: rgb(250, 250, 250);
  // background-color: #f6f9f6;
  // margin: 10px;
  // padding: 10px;
  // border-width: 3px;
  // margin-top: 2px;
  // margin-bottom: 20px;
  // margin-left: 20px;
  // margin-right: 20px;
  // padding-top: 15px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 2px;
  height: calc(100% - 48px);
  overflow: auto;
  white-space: pre-wrap;
}

.chatlog {
  font: 15px 
}
#chatbox {
  font-size: 0.85rem ;
  width: 100%;
}
#marketingname {
  width: 100%;
}
.helloworld {
  width: 100%;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: pink;
}