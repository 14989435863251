
.legend-container {
  align-items: center;
  flex: 0 1 auto;
  align-self: center;
  margin: 0 auto;
}
.legend {
  margin-bottom: 0.5em;
}
.tooltip-box{
  padding: 1.25rem;
}

.legend-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 1em;
}
.legend-flex { 
display: flex; 
flex-wrap: wrap; 
justify-content: flex-start;
  margin:0 0rem 1rem 4.5rem ; 
}

.legends { 
  margin: 1.5px; 
  padding: 0.5rem;
  color:white;
}
.legend-circle{

stroke: black;
margin: 1.5px; 
  padding: 0.5rem;
  color:white;
border-radius: 50%;
width: 10px;

}
*{
  margin: 0;
  padding: 0;
}
.dot {
  stroke: #000;
}
.tooltip {
  position: absolute;
  // margin-left: 1.1rem;
  font-size: 1rem;  
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.90);
  // border: 1px solid black;
  border-radius: 1rem;
  padding: 0 0rem 0rem 0;
  box-shadow: 2px 2px 4px 1px darkgrey;
  color: black;
  z-index: 10000000;
}
.tool {
  border: 3px solid red;
}
.axis-label {
  // background-color: #4472c4;
  // color: white;
  padding: 0.5rem;
  font-size: 0.85rem !important;
  font-weight: 400;
}
// .xAxis {
//   position: absolute;
  
//   margin-top:-3rem;
//   margin-left: 45rem;
//   text-align: center;
  
// }
.yAxis {
  padding: 1rem;
  position: absolute;
  margin-top:-19.5rem;
  margin-right: 3rem;
  text-align: center;
  
  transform:rotate(-90deg);
  
}
.stage{
  padding: 1rem;
  border:1px solid red;
}
.tooltip-box{
  padding: 1.25rem;
}
.flex-container{
  display: flex;
  margin: 0.5rem;
}
.flex-item-team{
  margin-right: 1rem;
}
.flex-item-stage{
  margin-left: 1rem;
}
.heading-style{
 
  font-weight: 600;
}