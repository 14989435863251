.axis {
  font-size: 14px;
  // font-weight: bold;
}

.axis path,
.axis line {
  // fill: none;
  // stroke: none;
  stroke-width: 2px;
  shape-rendering: crispEdges;
}

.grid path {
  // stroke: none;
}

.grid line {
  // stroke: #E0E0E0;
  // shape-rendering: crispEdges;
}

// .data-line {
//   fill: none;
//   stroke: #3C92BA;
//   stroke-width: 4px;
// }

.axis-title {
  text-anchor: end;
  fill: #5d6971;
  font-weight: normal;
}

.axis-tspan {
  font-size: 12px;
}

.tooltip-line-chart {
  position: absolute;
  // margin-left: 1.1rem;
  font-size: 1rem;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.9);
  // border: 1px solid black;
  border-radius: 1rem;
  padding: 0 0rem 0rem 0;
  box-shadow: 2px 2px 4px 1px darkgrey;
  color: black;
  z-index: 10000000;
}

.tooltip-grid-container {
  font-size: 0.75rem;
  margin: 0.5rem;
  width: 14rem;
  display: grid;
  grid-template-columns: 9rem auto;
  border-top: 1px solid lightgrey;
}

.tooltip-grid-container-heading {
  border-top: none;
}
